<template>
  <div v-if="question">
    <div class="flex justify-between mb-1">
      <label class="block text-sm font-medium leading-5 text-gray-700">{{
        question.name
      }}</label>
      <span class="text-sm leading-5 text-gray-500" v-if="question.required"
        >Required</span
      >
    </div>
    <div class="grid grid-row-4 grid-col-3 gap-2 max-w-md">
      <sqr-input-text
        class="col-span-3"
        autocomplete="address-line1"
        :placeholder="question.streetAddressPlaceholder || 'Street address'"
        :value="answer.streetAddress"
        @change="change('streetAddress', $event)"
        :disabled="disabled"
        :v="v"
      />
      <sqr-input-text
        class="col-span-3"
        autocomplete="address-line2"
        :placeholder="
          question.postOfficeBoxNumberPlaceholder || 'Post Office box number'
        "
        :value="answer.postOfficeBoxNumber"
        @change="change('postOfficeBoxNumber', $event)"
        :disabled="disabled"
        :v="v"
      />
      <sqr-input-text
        autocomplete="postal-code"
        :placeholder="question.postalCodePlaceholder || 'Postal Code'"
        :value="answer.postalCode"
        @change="change('postalCode', $event)"
        :disabled="disabled"
        :v="v"
      />
      <sqr-input-text
        autocomplete="postal-code"
        :placeholder="question.addressLocalityPlaceholder || 'Locality'"
        :value="answer.addressLocality"
        @change="change('addressLocality', $event)"
        :disabled="disabled"
        :v="v"
      />
      <sqr-input-text
        autocomplete="address-level1"
        :placeholder="question.addressRegionPlaceholder || 'Region'"
        :value="answer.addressRegion"
        @change="change('addressRegion', $event)"
        :disabled="disabled"
        :v="v"
      />
      <sqr-input-text
        class="col-span-3"
        autocomplete="country-name"
        :placeholder="question.addressCountryPlaceholder || 'Country'"
        :value="answer.addressCountry"
        @change="change('addressCountry', $event)"
        :disabled="disabled"
        :v="v"
      />
    </div>
  </div>
</template>

<script>
import { assocPath } from 'ramda';
import SqrInputText from '@/sqrd-ui/SqrInputText';
export default {
  name: 'AddressView',
  components: { SqrInputText },
  props: {
    question: { type: Object },
    answer: { type: Object, default: () => ({}) },
    disabled: { type: Boolean },
    v: { type: Object },
  },
  methods: {
    change(prop, value) {
      const step1 = assocPath([prop], value, this.answer ?? {});
      const label = [
        step1.streetAddress,
        step1.postOfficeBoxNumber,
        step1.postalCode,
        step1.addressLocality,
        step1.addressRegion,
        step1.addressCountry,
      ]
        .filter(v => Boolean(v))
        .join(' ');
      const step2 = assocPath(['label'], label, step1);
      this.$emit('change', step2);
    },
  },
};
</script>
